.card {
    width: 815px;
    height: 81px;
    flex-shrink: 0;
    background-color: #fff;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
}
/* IconTextIconComponent styles */
.icon-text-icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: center;
    padding: 20px;
  }
  
  /* IconText styles */
  .icon-text {
    display: flex;
    align-items: center;
    color: #131135;
    margin: auto;
  }
  
  .icon {
    font-size: 24px;
    margin-right: 5px;
    fill: #164983;
  }
  
  .icotext {
    color: #131135;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px; /* 188.889% */
  }
  