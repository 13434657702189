/* Container styles */
.image-text-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

/* Text section styles */
.text-section {
  flex: 1;
  padding: 20px;
  color: #26262B;
}

/* Image section styles */
.image-section {
  flex: 1;
  text-align: center;
}

/* Image on the right */
.image-on-right .image-section {
  order: 2;
}

.image-on-right .text-section {
  order: 1;
}

/* Image on the left */
.image-on-left .image-section {
  order: 1;
}

.image-on-left .text-section {
  order: 2;
}
.image-text {
  width: 488px;
  height: 423px;
  flex-shrink: 0;
}
