.text-container{
    display: flex;
    width: 718px;
    height: 78px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
}

.text{
    color: #164983;
    text-align: center;
    font-family: Urbanist;
    font-size: 36.5px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px; /* 136.986% */
}

