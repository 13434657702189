/* Header styles */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 20px;
  background-color: transparent;
}

/* Logo styles */
.logo img {
  width: 100px; /* Adjust the width of the logo */
  height: auto;
}

/* Navigation styles */
.nav-links ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin-right: 20px;
}

.nav-links li a {
  text-decoration: none;
  color: #164983;
}

.nav-links li a:hover {
  color: #164983;
}

/* Language selector styles */
.language-selector {
  display: flex;
  align-items: center;
}

.language-selector select {
  margin-right: 10px;
  padding: 5px;
  border: none;
  background-color: transparent;
  color:#164983;
}

/* Auth buttons styles */
.auth-buttons {
  display: flex;
  align-items: center;
}

.language-text {
  margin-right: 10px;
  color: #164983;
}
.login-btn,
.signup-btn {
  padding: 8px 16px;
  margin-left: 10px;
  border: none;
  cursor: pointer;
}

.login-btn {
  background-color: #164983;
  color: #fff;
  border-radius: 10px;
}

.signup-btn {
  color: #333;
  background-color: transparent;
  border: 1px solid #164983;
  border-radius: 10px;
}
