/* Card styles */
.cardTwo {
    width: 413px;
    padding: 20px;
    border-radius: 24px;
    background: linear-gradient(180deg, #164983 0%, #2AB7C0 100%);
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  /* Card header styles */
  .card-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Card body styles */
  .card-body ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .card-body ul li {
    margin-bottom: 10px;
    text-align: left;
    padding-left: 10%;
  }
  
  .card-body button {
    padding: 8px 16px;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: #1A6190;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 200% */
  }
  
  .card-body button:hover {
    background-color: #1A6190;
    color: #fff;
  }
  
  .para {
    color: #FFF;
    text-align: center;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 50px; /* 357.143% */
  }
  hr {
    stroke-width: 1px;
    stroke: #FFF;
    opacity: 0.2;
  }

  .circle {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: transparent;
    text-align: center;
    line-height: 18px;
    margin-right: 10px;
    border: #FFF solid;
    font-weight: 100; /* Adjust font weight */
  }
  
  /* Plus icon styles */
  .plus-icon {
    color: #fff;
    font-size: 10px;
    font-weight: 100; /* Adjust font weight */
  }