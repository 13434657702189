/* Footer styles */
.footer {
    background-color: transparent;
    color: #fff;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Footer logo styles */
  .footer-logo img {
    width: 100px; /* Adjust width as needed */
  }
  
  /* Social links styles */
  .social-links {
    display: flex;
  }
  
  .social-links a {
    margin-right: 10px;
    text-decoration: none; /* Remove underline */
  }
  
  .social-icon {
    color: #164983;
    font-size: 20px;
  }
  
  /* Additional links styles */
  .additional-links a {
    margin-right: 10px;
    color: var(--ebex-black, #131135);
    font-family: A GOOGLE;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 200% */
    text-decoration: none; /* Remove underline */
  }
  
  .additional-links a:hover {
    text-decoration: underline;
  }
  