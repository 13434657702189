/* Container styles */
.container {
    position: relative;
    background: linear-gradient(180deg, rgba(62, 84, 251, 0.1) 0%, rgba(57, 145, 203, 0.1) 51.98%, rgba(57, 221, 213, 0.1) 94.69%);
  }
  
  /* Gradient background styles */
  .gradient-background {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    border-radius: 10px;
  }
  
  /* Centered image styles */
  .centered-image {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 70%;
    max-height: 70%;
  }
  